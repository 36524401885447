<template>
  <svg
    class="fill-current"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7.3c-.4-.4-1-.4-1.4 0L8 6.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L6.6 8 .3 14.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L8 9.4l6.3 6.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.4 8l6.3-6.3c.4-.4.4-1 0-1.4Z"
    />
  </svg>
</template>
