<template>
  <Transition name="fade">
    <div v-if="props.show" class="bg-black/50" />
  </Transition>
</template>

<script lang="ts" setup>
interface BaseShadeProps {
  show?: boolean
}

const props = withDefaults(defineProps<BaseShadeProps>(), { show: false })
</script>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500 ease-slide;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
