<template>
  <div class="fixed inset-0 left-auto z-40 w-0">
    <BaseShade
      :show="props.show"
      class="fixed inset-0 cursor-w-resize"
      @click="onDismiss"
    />
    <Transition name="tray">
      <div
        v-if="props.show"
        class="absolute inset-0 left-auto w-screen overflow-y-auto p-3 md:w-auto md:p-5"
      >
        <div
          :class="props.fullHeight ? 'h-full' : 'md:h-full'"
          class="relative w-full rounded-[10px] bg-cream-light px-7 py-8 md:w-[480px] xl:rounded-[20px]"
        >
          <BaseButton
            class="absolute right-3 top-3 h-12 w-12 rounded-full md:right-5 md:top-5"
            @click="onDismiss"
          >
            <SvgGlyphClose />
          </BaseButton>
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
interface BaseSideTrayProps {
  dismissable?: boolean
  fullHeight?: boolean
  show?: boolean
}

const props = withDefaults(defineProps<BaseSideTrayProps>(), {
  dismissable: true,
  fullHeight: false,
  show: false,
})

const uiStore = useUIStore()
const emit = defineEmits(['dismiss'])

function onDismiss() {
  if (props.dismissable) {
    emit('dismiss')
  }
}

onKeyStroke('Escape', onDismiss)

onMounted(() => {
  const { y: currentScroll } = useScroll(window)
  const { y: scrollTop } = useWindowScroll()

  watch(scrollTop, (newValue) => {
    if (!uiStore.scrollLock.enabled) {
      uiStore.scrollLock.scrollTop = newValue
    }
  })

  watch(toRef(props, 'show'), (newValue) => {
    uiStore.scrollLock.enabled = newValue

    if (!newValue) {
      const cachedScrollTop = uiStore.scrollLock.scrollTop

      nextTick(() => {
        currentScroll.value = cachedScrollTop
      })
    }
  })
})
</script>

<style lang="postcss" scoped>
.tray-enter-active,
.tray-leave-active {
  @apply transition-transform duration-500 ease-slide;
}

.tray-enter-from,
.tray-leave-to {
  @apply translate-x-full;
}
</style>
